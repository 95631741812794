import React, { Fragment, useCallback, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import classnames from 'classnames';

import CreatorActions from '../../CreatorActions/CreatorActions';

import styles from './BloggerInfo.pcss';
import { getMarks } from './data';

import { amplitude } from 'Helpers/amplitude';
import addCreatorToCampaignShortlist from 'Mutations/AddCreatorToCampaignShortlist.Mutation';
import removeCreatorFromCampaignShortlist from 'Mutations/RemoveCreatorFromCampaignShortlist.Mutation';
import unblockCreator from 'Mutations/UnblockCreator.Mutation';
import blockCreator from 'Mutations/BlockCreator.Mutation';
import unarchiveProject from 'Mutations/UnarchiveProject.Mutation';
import ArchiveProjectRequest from 'Modal/advertiser/ArchiveProjectRequest/ArchiveProjectRequest';
import { getCreatorData } from 'Util/creatorData';
import { formatValue } from 'Util/numberFormatter';
import { getCreatorStatLink } from 'Util/links';
import { createMediasList, createPortfolioList, createCountriesList } from 'Util/dataFormatter';
import AvatarWithBadge from 'Molecules/AvatarWithBadge/AvatarWithBadge';
import Button from 'Atoms/Button/Button';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Atoms/Text/Text';
import UIText from 'Components/ui/Text/Text';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import ImagesWithFallback from 'Components/ImagesWithFallback/ImagesWithFallback';
import { CONTENT_CREATION } from 'Constants/general';
import AmazonStoreLink from 'Molecules/AmazonStoreLink/AmazonStoreLink';
import TextButton from 'Components/ui/TextButton/TextButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import UIButton from 'Components/ui/Button/Button';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Image from 'Components/ui/Image/Image';
import thumbsUpFilledBlueImg from 'Images/icons/thumbs-up-filled.svg';
import thumbsUpImg from 'Images/icons/thumbs-up-accept.svg';
import { BloggerInfo_project$key } from 'GraphTypes/BloggerInfo_project.graphql';

interface Props {
  project: BloggerInfo_project$key;
  className: string;
  canCreateMultipleDeals: boolean;
  isAdmin: boolean;
  onRemoveClick: () => void;
  onCustomListSelectionOpen: () => void;
  onCustomListSelectionClose: () => void;
}

const BloggerInfo: React.FC<Props> = (props) => {
  const {
    className,
    isAdmin,
    project,
    canCreateMultipleDeals,
    onRemoveClick,
    onCustomListSelectionOpen,
    onCustomListSelectionClose,
  } = props;

  const data = useFragment(
    graphql`
      fragment BloggerInfo_project on Project {
        id
        favorite
        blockedCreator
        ...CreatorActions_project
        launch {
          id
        }
        completion {
          id
        }
        archivation {
          id
          side
        }
        archivationRequest {
          acceptance {
            id
          }
          rejection {
            id
          }
          side
        }
        shortlisted
        campaign {
          id
          type
          organization {
            id
          }
        }
        creator {
          id
          username
          type
          profile {
            badge
            countries {
              name
            }
            videoResumeFile {
              ... on Video {
                transformations {
                  autoUrl
                  collageThumbnailUrl
                }
              }
              secureUrl
            }
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
            ...useCreatorAmazonStorelink_creator
          }
          portfolioItems(first: 10) {
            edges {
              node {
                file {
                  id
                  type
                  thumbnailUrl
                  ... on Image {
                    transformations {
                      collageThumbnailUrl
                    }
                  }
                  ... on Video {
                    transformations {
                      autoUrl
                      collageThumbnailUrl
                    }
                  }
                }
              }
            }
          }
          ... on YoutubeCreator {
            channel {
              id
              name
              subscribers
            }
          }
          ... on TiktokCreator {
            tiktokUser: user {
              followedByCount
              engagementRate
            }
          }
          ... on InstagramCreator {
            user {
              fullName
              followedByCount
              engagementRate
              estimatedLikes
            }
            medias(first: 8) {
              edges {
                node {
                  id
                  videoUrl
                  shortcode
                  instagramUrl
                  thumbnailUrl
                  standardResUrl
                  instagramUrl
                }
              }
            }
          }
        }
      }
    `,
    project
  );

  const tracking = useTracking();

  const {
    creator,
    completion,
    archivation,
    archivationRequest,
    id: projectId,
    blockedCreator,
  } = data;
  const { shortlisted, launch } = data;
  const creatorData = getCreatorData(creator);

  const { type, avaSrc } = creatorData;
  const marksList = getMarks(creator?.type);

  const hasArchivationRequest =
    archivationRequest && !archivationRequest.rejection && !archivationRequest.acceptance;

  const canArchive = !completion && !archivation && !hasArchivationRequest;
  const canUnarchive =
    !completion && archivation && archivation.side !== 'CONTRACTOR' && !hasArchivationRequest;

  const {
    campaign: { id: campaignId, type: campaignType, organization },
  } = data;

  const organizationId = organization?.id;

  const {
    creator: {
      id: creatorId,
      profile: { badge },
    },
  } = data;

  const isContentCreation = campaignType === CONTENT_CREATION;

  const handleLinkClick = () => {
    tracking.trackEvent({
      element: `${type}_link`,
      event: 'clicked',
    });
    amplitude.sendEvent({ id: 253, category: 'project', name: 'project_profile_handle_clicked' });
  };

  const handleArchiveClick = () => {
    tracking.trackEvent({
      element: 'remove_button',
      event: 'clicked',
    });
    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: { admin: isAdmin, source: 'CHAT', actionName: 'archive', campaign_id: campaignId },
    });
    onRemoveClick();
  };

  const handleUnarchiveClick = () => {
    tracking.trackEvent({
      element: 'remove_button',
      event: 'clicked',
    });
    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: { admin: isAdmin, source: 'CHAT', actionName: 'unarchive', campaign_id: campaignId },
    });
    unarchiveProject({ projectId });
  };

  const handleToggleToShortlist = useCallback(() => {
    const {
      creator: { id: creatorId },
      campaign: { id: campaignId },
      shortlisted,
    } = data;

    if (shortlisted) {
      amplitude.sendEvent<192>({
        id: '192',
        category: 'chat',
        name: 'chat_creator_remove_shortlist',
        param: {
          creator_id: creatorId,
        },
      });
    } else {
      amplitude.sendEvent<191>({
        id: '191',
        category: 'chat',
        name: 'chat_creator_add_shortlist',
        param: {
          creator_id: creatorId,
        },
      });
    }

    if (shortlisted) {
      removeCreatorFromCampaignShortlist({ creatorId, campaignId, projectId });
    } else {
      addCreatorToCampaignShortlist({ creatorId, campaignId, projectId });
    }
  }, [data, shortlisted, projectId, creatorId]);

  const handleStorefrontClick = () => {
    amplitude.sendEvent({
      id: '156',
      category: 'brand',
      name: 'amazon_link_clicked',
      param: {
        source: 'chat',
      },
    });
  };

  const renderBasic = () => {
    const {
      creator: {
        profile: { countries = [] },
      },
    } = data;
    const { username, fullName, link } = creatorData;

    const countriesList = createCountriesList(countries);
    const subtitle = countriesList || fullName || undefined;

    return (
      <div className={styles.names}>
        <SimpleLink
          href={link}
          onClick={handleLinkClick}
          target="_blank"
          className={styles.usernameWrap}
        >
          <Text
            weight="500"
            text={username}
            color="creamCan"
            overflow
            data-test="bloggerInfo:text:unknown"
          />
        </SimpleLink>
        {subtitle && (
          <Text
            size="sm"
            color="grayDog"
            text={subtitle}
            overflow
            data-test="bloggerInfo:text:unknown"
          />
        )}
      </div>
    );
  };

  const createStatistic = () => {
    return (
      <div className={styles.marks}>
        {marksList.map((item) => {
          const { id, format, msg } = item;
          const value = creatorData[id];
          const formattedValue = Number(value) ? formatValue({ format, value }) : '—';

          return (
            <div className={styles.mark} key={msg}>
              <Text weight="500" text={formattedValue} data-test="bloggerInfo:text:unknown" />
              <Text
                size="sm"
                color="grayDog"
                msg={`creator.${msg}`}
                data-test="bloggerInfo:text:unknown"
              />
            </div>
          );
        })}
      </div>
    );
  };

  const createImages = () => {
    const { creator } = data;

    if (!creator) return null;

    const { portfolioItems, medias, profile } = creator;
    const videoResumeFile =
      profile && profile.videoResumeFile
        ? [
            {
              thumbnailUrl: profile.videoResumeFile.transformations?.collageThumbnailUrl,
              videoUrl: profile.videoResumeFile.transformations?.autoUrl,
              media: 'VIDEO',
            },
          ]
        : [];
    const items = [
      ...videoResumeFile,
      ...createPortfolioList(portfolioItems),
      ...createMediasList(medias),
    ];

    if (items.length === 0) return null;

    return (
      <Fragment>
        <div className={styles.images}>
          <ImagesWithFallback className={styles.image} items={items} limit={5} fullScreen={true} />
        </div>
      </Fragment>
    );
  };

  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const handleBlocklistChange = () => {
    if (blockedCreator) {
      amplitude.sendEvent({
        id: '160',
        category: 'blocklist',
        name: 'creator_blocklist_removed_chat',
        param: {
          creatorId,
          projectId,
        },
      });
      unblockCreator({ creatorId, projectId });
    } else {
      openDrawer('add-creator-blocklist');
    }
  };

  const handleBlockCreator = useCallback(() => {
    amplitude.sendEvent({
      id: '159',
      category: 'blocklist',
      name: 'creator_blocklisted',
      param: {
        creatorId,
        projectId,
      },
    });
    blockCreator({ creatorId, projectId });
    closeDrawer('add-creator-blocklist');
  }, [creatorId, projectId]);

  const names = renderBasic();

  const link = getCreatorStatLink({ campaignId, creatorId });

  return (
    <div className={`${className} ${styles.root}`}>
      <Drawer rootKey={'add-creator-blocklist'} className={styles.blocklistDrawerContainer}>
        <div className={styles.blocklistDrawer}>
          <UIText msg="chat.creator.validation.title" type="d2" />
          <UIText msg="chat.creator.validation.description" type="md" />
          <div className={styles.blocklistControls}>
            <UIButton
              msg="chat.creator.validation.btn.cancel"
              onClick={() => closeDrawer('add-creator-blocklist')}
              color="white"
              fluid
            />
            <UIButton
              msg="chat.creator.validation.btn.ok"
              color="black"
              onClick={handleBlockCreator}
              fluid
            />
          </div>
        </div>
      </Drawer>
      <div className={styles.info}>
        <div className={styles.avaWrap}>
          <SimpleLink href={creatorData.link} onClick={handleLinkClick} target="_blank">
            <AvatarWithBadge badge={badge} avaData={{ size: 40, src: avaSrc }} />
          </SimpleLink>
        </div>
        {names}
        <div className={styles.controls}>
          {blockedCreator && (
            <Tooltip
              id={`blockedCreator_${blockedCreator.toString()}`}
              tooltipMsg={
                blockedCreator ? 'chat.creator.unblock.tooltip' : 'chat.creator.block.tooltip'
              }
              place="top"
            >
              <TextButton
                icon="Blocklist"
                className={classnames({ [styles.blockedCreator]: blockedCreator })}
                onClick={handleBlocklistChange}
                data-test="bloggerInfo:textButton:unknown"
              />
            </Tooltip>
          )}
          <Tooltip
            id="dialogs_shortlist"
            tooltipMsg={shortlisted ? 'dropdown.remove_to_shortlist' : 'dropdown.add_to_shortlist'}
            place="top"
          >
            <AlterButton
              rightElement={
                <Image src={shortlisted ? thumbsUpFilledBlueImg : thumbsUpImg} size={24} />
              }
              type="white"
              hover={false}
              onClick={handleToggleToShortlist}
              className={styles.shortlistedButton}
              data-test="bloggerInfo:alterButton:shortlistedButton"
            />
          </Tooltip>
          <CreatorActions
            isAdmin={isAdmin}
            project={data}
            onCustomListSelectionOpen={onCustomListSelectionOpen}
            onCustomListSelectionClose={onCustomListSelectionClose}
            canCreateMultipleDeals={canCreateMultipleDeals}
          />
        </div>
      </div>
      <AmazonStoreLink
        creatorProfile={creator?.profile}
        onClick={handleStorefrontClick}
        className={styles.storeFront}
      />
      {createImages()}
      {!isContentCreation && createStatistic()}
      <SimpleLink href={link} className={styles.stat} target="_blank">
        <Button
          width="full"
          color="normal"
          msg="chat.view_stats"
          data-test="bloggerInfo:button:viewStats"
        />
      </SimpleLink>
      {(canArchive || canUnarchive) && !launch && (
        <Tooltip
          id={`archivation-${data.id}`}
          tooltipMsg={canArchive ? 'chat.project_archive' : 'chat.project_unarchive'}
          className={styles.button}
          place="top"
        >
          <Button
            width="full"
            color="normal"
            leftIconName={canArchive ? 'crossedEye' : 'undo'}
            msg={canArchive ? 'chat.project_archive' : 'chat.project_unarchive'}
            className={styles.archive}
            onClick={canArchive ? handleArchiveClick : handleUnarchiveClick}
            data-test="bloggerInfo:button:archive"
          />
        </Tooltip>
      )}
      <ArchiveProjectRequest projectId={projectId} />
    </div>
  );
};

const BloggerInfoWrap = track({
  subsection: 'blogger_info',
})(BloggerInfo);

export default BloggerInfoWrap;
